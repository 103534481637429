import * as React from "react";

interface LifeTimeProps {
    used: number,
    available: number,
    of: string
}

export default function LifeTime({used, available, of}: LifeTimeProps): JSX.Element {
    const percentage = used / available * 100;
    const opacity = Math.max(0.1, used / available);
    const width = {
        width: percentage.toFixed(2) + "%",
        opacity: opacity
    }
    return (
        <div className="progressBar">
            <div className="progress" style={width}>&nbsp;</div>
            <div className="progressLabel">
                <span>{used.toLocaleString()}</span> of <span>{available.toLocaleString()}</span>
                <span> {of}</span>
                <span> = </span>
                <span>{percentage.toFixed(2)}%</span>
            </div>
        </div>
    );
}
